<template>
    <v-card class="fill-height" fluid>
        <v-toolbar dark color="primary">
            <router-link :to="{ path: '/' }" tag="v-btn">
                <v-btn icon dark>
                    <v-icon>close</v-icon>
                </v-btn>
            </router-link>
            <v-toolbar-title>{{ $t("Faça parte da nossa time!") }}</v-toolbar-title>
        </v-toolbar>

        <v-card color="primary">
            <v-card-text primary-title class="white--text">
                {{ $t("Caso você tenha interesse em fazer parte da nossa rede de especialistas, preencha o formulário abaixo e verifique seu e-mail.") }}
            </v-card-text>
        </v-card>
        <v-container class="text-center">
            <v-form ref="form" v-model="valid" @submit.prevent="validate">
                <v-text-field v-model="item.name" :rules="[rules.required, rules.min(10)]" :label="$t('Nome')" required />
                <v-flex xs12 mb-8>
                    <v-text-field v-model="item.crm" :rules="[rules.required, rules.crmValidatorDigit]" label="CRM" v-mask="'##.###'" return-masked-value class="my-0 py-0" />
                </v-flex>
                <v-text-field type="email" v-model="item.email" :rules="[rules.required, rules.email]" :label="$t('E-mail')" required />
                <v-text-field
                    type="tel"
                    v-model="item.phone"
                    :rules="[rules.required, rules.cellphone]"
                    :label="$t('Celular')"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    return-masked-value
                    required
                />
                <v-btn color="primary" @click="validate" :loading="saving">{{ $t("Salvar") }}</v-btn>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
import { mask } from "vue-the-mask";

import rules from "@/helpers/rules";
export default {
    directives: { mask },
    name: "Register",
    data() {
        return {
            rules,
            saving: false,
            valid: true,
            item: {
                name: "",
                email: "",
                phone: "",
            },
        };
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.item.redirect = this.$store.state.routeBeforeRegister;
                this.saving = true;
                this.$http
                    .post("doctor-register", this.item)
                    .then((result) => {
                        this.$router.replace({ path: "/login" });
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Verifique seu email para concluir o cadastro."));
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                    });
            }
        },
    },
    // created() {
    //     this.$store.commit("CLEAR_LOGIN");
    // },
};
</script>